import React, { useState } from 'react';
import { ContentCarousel, SeriesModal } from 'components/organisms';
import { BrowseTemplate } from 'components/templates';
import { Container } from 'reactstrap';
import { Film, MediaItem, Series } from 'types/models';
import { isSeries } from 'types/typeGuards';

type BrowsePageFeatured = MediaItem;

type BrowsePageSection = {
    title: string;
    items: MediaItem[];
};

interface BrowsePageProps {
    featured: BrowsePageFeatured;
    sections: BrowsePageSection[];
    fetchItemById: (id: string) => Series | Film | undefined;
}

const BrowsePage: React.FC<BrowsePageProps> = ({ featured, sections, fetchItemById }) => {
    const [modal, setModal] = useState(false);

    const [selectedItem, setSelectedItem] = useState<Series | Film>();

    const handleItemClick = async (id: string) => {
        const fetchedItem = await fetchItemById(id);

        setSelectedItem(fetchedItem);

        setModal(true);
    };

    const closeModal = () => {
        setSelectedItem(undefined);

        setModal(false);
    };

    return (
        <BrowseTemplate featured={featured}>
            <Container>
                {sections.map(({ title, items }, index) => (
                    <section key={index}>
                        <h2 className="my-5">{title}</h2>
                        <ContentCarousel items={items} onItemClick={handleItemClick} />
                    </section>
                ))}
            </Container>

            {selectedItem && isSeries(selectedItem) && (
                <SeriesModal isOpen={modal} series={selectedItem} toggle={closeModal} />
            )}
        </BrowseTemplate>
    );
};

export default BrowsePage;
