import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Browse, Watch } from 'routes';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigate to="/browse" replace />} />
                <Route path="/browse" element={<Browse />} />
                <Route path="/watch/:id" element={<Watch />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
