import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Nav, NavItem, NavLink } from 'reactstrap';

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => (
    <Container>
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4">
            <p className="col-md-4 mb-0 text-body-secondary">© 2024 Company, Inc</p>

            <Link
                to="/"
                className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                Logo
            </Link>

            <Nav className="col-md-4 justify-content-end">
                <NavItem>
                    <NavLink tag={Link} to="/browse" className="nav-link px-2">
                        Browse
                    </NavLink>
                </NavItem>
            </Nav>
        </footer>
    </Container>
);

export default Footer;
