import React from 'react';
import { Footer, Masthead, Topnav } from 'components/organisms';
import { MediaItem } from 'types/models';

interface BrowseTemplateProps {
    children?: React.ReactNode;
    featured: MediaItem;
}

const BrowseTemplate: React.FC<BrowseTemplateProps> = ({ children, featured }) => (
    <React.Fragment>
        <Topnav />

        <Masthead
            backgroundImage="https://placehold.co/1000x800"
            title={featured.title}
            subtitle={featured.description}
        />

        <main>{children}</main>

        <Footer />
    </React.Fragment>
);

export default BrowseTemplate;
