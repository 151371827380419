import { Series } from 'types/models';
import series from '../../data/series.json';

function getFeaturedSeries(): Series {
    const randomSeries = series[Math.floor(Math.random() * series.length)];

    return randomSeries;
}

export default getFeaturedSeries;
