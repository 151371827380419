import React from 'react';
import { Col, Container, Row } from 'reactstrap';

interface MastheadProps {
    backgroundImage: string;
    subtitle: string;
    title: string;
}

const Masthead: React.FC<MastheadProps> = ({ backgroundImage, subtitle, title }) => (
    <header
        className="vh-100"
        style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
        }}>
        <Container className="h-100 d-flex flex-column justify-content-end">
            <Row>
                <Col xs="12" className="text-left">
                    <h1 className="fw-light">{title}</h1>
                    <p className="lead">{subtitle}</p>
                </Col>
            </Row>
        </Container>
    </header>
);

export default Masthead;
