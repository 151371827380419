import React from 'react';
import { WatchPage } from 'components/pages';
import { useParams } from 'react-router-dom';
import getSeriesById from 'api/getSeriesById';

interface WatchProps {}

const Watch: React.FC<WatchProps> = () => {
    const { id } = useParams() as { id: string };

    const data = getSeriesById(id);

    if (!data) {
        return null;
    }

    return <WatchPage mediaItem={data} />;
};

export default Watch;
