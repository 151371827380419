import React, { useEffect } from 'react';
import { faChevronRight, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
    Card,
    CardHeader,
    Col,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from 'reactstrap';
import { Season, Series } from 'types/models';

interface SeriesModalProps {
    isOpen: boolean;
    series: Series;
    toggle: () => void;
}

const SeriesModal: React.FC<SeriesModalProps> = ({ isOpen, series, toggle }) => {
    const [selectedSeason, setSelectedSeason] = React.useState<Season>();

    useEffect(() => {
        setSelectedSeason(series.seasons[0]);
    }, [series]);

    return (
        <Modal size="xl" isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>{series.title}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md="4">
                        <Card>
                            <CardHeader tag="h5">Seasons</CardHeader>
                            <ListGroup flush>
                                {series.seasons.map((season, index) => {
                                    const isActive = selectedSeason?.id === season.id;

                                    return (
                                        <ListGroupItem
                                            key={index}
                                            active={isActive}
                                            onClick={() => setSelectedSeason(season)}
                                            action>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex flex-column">
                                                    {season.title}
                                                </div>
                                                {isActive && (
                                                    <FontAwesomeIcon icon={faChevronRight} />
                                                )}
                                            </div>
                                        </ListGroupItem>
                                    );
                                })}
                            </ListGroup>
                        </Card>
                    </Col>
                    <Col md="8">
                        {selectedSeason && (
                            <Card>
                                <CardHeader tag="h5">{selectedSeason.title}</CardHeader>
                                <ListGroup flush>
                                    {selectedSeason.episodes.map((episode, index) => (
                                        <ListGroupItem
                                            key={index}
                                            tag={Link}
                                            to={`/watch/${episode.id}`}
                                            action>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center flex-shrink-0 me-3">
                                                    <div className="me-3">
                                                        <img
                                                            className="img-fluid"
                                                            src="https://placehold.co/50x50"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <div className="fw-bold line-height-normal mb-1">
                                                            {episode.title}
                                                        </div>
                                                        <div className="small text-muted line-height-normal">
                                                            {episode.description}
                                                        </div>
                                                    </div>
                                                </div>
                                                <FontAwesomeIcon icon={faPlay} />
                                            </div>
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </Card>
                        )}
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default SeriesModal;
