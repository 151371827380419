import React from 'react';
import { MediaItem } from 'types/models';

interface WatchPageProps {
    mediaItem: MediaItem;
}

const WatchPage: React.FC<WatchPageProps> = ({ mediaItem }) => {
    return <div>{mediaItem.title}</div>;
};

export default WatchPage;
