import React from 'react';
import { getFeaturedSeries, getGenres, getSeriesByGenre, getSeriesById } from 'api';
import { BrowsePage } from 'components/pages';

interface BrowseProps {}

const Browse: React.FC<BrowseProps> = () => {
    const sections = getGenres().map(genre => {
        const items = getSeriesByGenre(genre.name);

        return { title: genre.name, items };
    });

    const featured = getFeaturedSeries();

    const fetchItemById = (id: string) => {
        return getSeriesById(id);
    };

    return <BrowsePage featured={featured} sections={sections} fetchItemById={fetchItemById} />;
};

export default Browse;
