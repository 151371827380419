import { Series } from 'types/models';
import series from '../../data/series.json';

function getSeriesById(seriesId: string): Series | undefined {
    const foundSeries = series.find(series => series.id === seriesId);

    if (foundSeries) {
        return foundSeries;
    }

    return undefined;
}

export default getSeriesById;
