import React, { useState } from 'react';
import {
    Card,
    CardBody,
    CardImg,
    CardText,
    CardTitle,
    Carousel,
    CarouselControl,
    CarouselItem,
    Col,
    Row
} from 'reactstrap';
import { MediaItem } from 'types/models';

type ContentCarouselItem = MediaItem;

type ContentCarouselItemClickHandler = (id: string) => void;

interface ContentCarouselProps {
    items: ContentCarouselItem[];
    onItemClick: ContentCarouselItemClickHandler;
}

const ContentCarousel: React.FC<ContentCarouselProps> = ({ items, onItemClick }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const [animating, setAnimating] = useState(false);

    const groupedItems = items.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 3);

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
    }, [] as MediaItem[][]);

    const next = (): void => {
        if (animating) return;
        const nextIndex = activeIndex === groupedItems.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = (): void => {
        if (animating) return;

        const nextIndex = activeIndex === 0 ? groupedItems.length - 1 : activeIndex - 1;

        setActiveIndex(nextIndex);
    };

    return (
        <Carousel activeIndex={activeIndex} next={next} previous={previous} interval={false}>
            {groupedItems.map((group, index) => (
                <CarouselItem
                    onExiting={() => setAnimating(true)}
                    onExited={() => setAnimating(false)}
                    key={index}>
                    <Row>
                        {group.map(({ id, title, description }, index) => (
                            <Col key={index} xs="4">
                                <Card
                                    className="h-100"
                                    onClick={() => onItemClick(id)}
                                    style={{ cursor: 'pointer' }}>
                                    <CardImg src="https://placehold.co/500x300" alt="..." top />
                                    <CardBody>
                                        <CardTitle tag="h4">{title}</CardTitle>
                                        <CardText>{description}</CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </CarouselItem>
            ))}

            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
    );
};

export default ContentCarousel;
